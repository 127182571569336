import React from 'react';
import ReactDOM from 'react-dom/client';
import './Stylesheets/index.css';
import App from './App';
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <App />
 
);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
            console.log('Service Worker registered: ', registration);
        })
        .catch(registrationError => {
            console.log('Service Worker registration failed: ', registrationError);
        });
    });
} 
